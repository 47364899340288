import { useEffect, useState } from "react";
import {
    GoArrowDown,
    GoArrowDownLeft,
    GoArrowDownRight,
    GoArrowLeft,
    GoArrowRight,
    GoArrowUp,
    GoArrowUpLeft,
    GoArrowUpRight,
    GoScreenFull,
} from "react-icons/go";

export const OverlayPosition = (props: { onSetPosition: (position: string) => void }) => {
    const [position, setPosition] = useState<string>("bottom-right");

    const handleSelectPosition = (e: any) => {
        setPosition(e.currentTarget.getAttribute("data-pos"));
        props.onSetPosition(e.currentTarget.getAttribute("data-pos"));
    };

    useEffect(() => {
        const items = document.querySelectorAll(".item");
        items.forEach((item) => {
            item.classList.remove("active");
        });
        const cell = document.querySelector(`[data-pos=${position}]`);
        cell && cell.classList.add("active");
    }, [position]);
    return (
        <div className="overlay-position">
            <div className="position-row">
                <GoArrowUpLeft className="item" data-pos="top-left" onClick={handleSelectPosition} />
                <GoArrowUp className="item" data-pos="top" onClick={handleSelectPosition} />
                <GoArrowUpRight className="item" data-pos="top-right" onClick={handleSelectPosition} />
            </div>
            <div className="position-row">
                <GoArrowLeft className="item" data-pos="left" onClick={handleSelectPosition} />
                <GoScreenFull className="item" data-pos="full" onClick={handleSelectPosition} />
                <GoArrowRight className="item" data-pos="right" onClick={handleSelectPosition} />
            </div>
            <div className="position-row">
                <GoArrowDownLeft className="item" data-pos="bottom-left" onClick={handleSelectPosition} />
                <GoArrowDown className="item" data-pos="bottom" onClick={handleSelectPosition} />
                <GoArrowDownRight className="item" data-pos="bottom-right" onClick={handleSelectPosition} />
            </div>
        </div>
    );
};
