type RequestMethod = "get" | "post" | "put";

interface RequestOptions {
    method: RequestMethod;
    body?: any;
}

const YOUR_CLIENT_ID = "566928826132-sfv6dsioi7j9abtnljl8srhd185vfvm5.apps.googleusercontent.com";
// const YOUR_REDIRECT_URI = "http://localhost:3000/GoogleAuthentication";
const YOUR_REDIRECT_URI = window.location.protocol + "//" + window.location.host + "/GoogleAuthentication";
const BASE_URL = "https://www.googleapis.com/dfareporting/v4";

// Function to generate a random state value
function generateCryptoRandomState() {
    const randomValues: any = new Uint32Array(2);
    window.crypto.getRandomValues(randomValues);

    // Encode as UTF-8
    const utf8Encoder = new TextEncoder();
    const utf8Array: any = utf8Encoder.encode(String.fromCharCode.apply(null, randomValues));

    // Base64 encode the UTF-8 data
    // const buf = Buffer.from(String.fromCharCode.apply(null, utf8Array), 'utf-8')
    // return buf.toString('base64')
    return btoa(String.fromCharCode.apply(null, utf8Array)).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

/**
 * Should be used on the redrect page that you pass back from google.
 * Will return true or false if parameters that are sent back from google are ok, you can then redirect the user.
 * @returns boolean
 */
// export const checkGoogleAuthParams = () => {
//     var fragmentString = window.location.hash.substring(1);
//     var params: any = {};
//     var regex = /([^&=]+)=([^&]*)/g,
//         m;
//     while (m === regex.exec(fragmentString)) {
//         params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
//     }
//     if (Object.keys(params).length > 0 && params["state"]) {
//         if (params["state"] === localStorage.getItem("state")) {
//             localStorage.setItem("asStudio-google-oauth2-params", JSON.stringify(params));
//             return true;
//         } else {
//             console.log("State mismatch. Possible CSRF attack");
//             return false;
//         }
//     }
// };
export const checkGoogleAuthParams = () => {
    const paramsToObj = (params: IterableIterator<[string, string]>) => {
        // const result: { [key: string]: any } = {};
        // for (const [key, value] of params) {
        //     result[key] = value;
        // }
        const result = Object.fromEntries(params);
        return result;
    };
    var fragmentString = window.location.hash.substring(1);
    const urlParams = new URLSearchParams(fragmentString);
    const params = paramsToObj(urlParams.entries());
    // var params = {};
    // var regex = /([^&=]+)=([^&]*)/g, m;
    // console.log(paramsToObj(urlParams.entries()))
    // while (m === regex.exec(fragmentString)) {
    //    params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    // }
    if (Object.keys(params).length > 0 && params["state"]) {
        if (params["state"] === localStorage.getItem("state")) {
            localStorage.setItem("asStudio-google-oauth2-params", JSON.stringify(params));
            return true;
        } else {
            console.log("State mismatch. Possible CSRF attack");
            return false;
        }
    } else {
        return false;
    }
};

export async function sendCmRequest(method: RequestMethod, endpoint: string, requestParams: any = {}, body?: any) {
    const storedOauth = window.localStorage.getItem("asStudio-google-oauth2-params");
    var params = storedOauth ? JSON.parse(storedOauth) : false;
    if (params && params["access_token"]) {
        try {
            const options: RequestOptions = {
                method,
            };
            if (body) options.body = JSON.stringify(body);
            requestParams.access_token = params["access_token"];
            // console.log(method);
            const response = await fetch(
                BASE_URL + endpoint + "?" + new URLSearchParams(requestParams).toString(),
                options
            );
            if (!response.ok) {
                oauth2SignIn();
                throw new Error(`Response status: ${response.status}`);
            }

            const json = await response.json();
            // console.log("Return: ", json);
            return json;
        } catch (error: any) {
            console.error(error.message);
        }

        // 	'https://dfareporting.googleapis.com/dfareporting/v4/userprofiles/9675155/campaigns?' +
        // 	'https://dfareporting.googleapis.com/dfareporting/v4/userprofiles/9675155/creatives?' +
    } else {
        oauth2SignIn();
    }
}

function oauth2SignIn() {
    // create random state value and store in local storage
    var state = generateCryptoRandomState();
    localStorage.setItem("state", state);

    // Google's OAuth 2.0 endpoint for requesting an access token
    var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

    // Create element to open OAuth 2.0 endpoint in new window.
    var form = document.createElement("form");
    form.setAttribute("method", "GET"); // Send as a GET request.
    form.setAttribute("action", oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    var params: any = {
        client_id: YOUR_CLIENT_ID,
        redirect_uri: YOUR_REDIRECT_URI,
        scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/ddmconversions https://www.googleapis.com/auth/dfareporting https://www.googleapis.com/auth/dfatrafficking",
        state: state,
        include_granted_scopes: "true",
        response_type: "token",
    };

    // Add form parameters as hidden input values.
    for (var p in params) {
        var input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", p);
        input.setAttribute("value", params[p]);
        form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
}
