import { useContext, useState } from "react";
import { adnPostFolio } from "../../../functions/adnuntius/folios";
import { GlobalStateManager, useGlobalState } from "../../../contexts/useGlobalState";
import { v4 as uuidv4 } from "uuid";
import { Checkbox } from "../../Checkbox";
import { useNavigate } from "react-router-dom";

export const CreateFolio = (props: {
    advertiserId: string;
    user: any;
    onCreateFolio: any;
    setVisible: any;
    teamId: string;
}) => {
    const [folioName, setFolioName] = useState<string>("");
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const navigate = useNavigate();

    const handleCheck = () => {
        setIsChecked(!isChecked);
    };

    const handleChangeFolioName = (e: any) => {
        setFolioName(e.target.value);
    };

    const handleCreateFolio = async () => {
        console.log(props.teamId);
        const folio = await adnPostFolio(globalState.get.token(), uuidv4(), {
            advertiserId: props.advertiserId,
            name: folioName,
            teamId: props.teamId,
        });
        if (folio.id) {
            props.setVisible();
            props.onCreateFolio();
            if (isChecked) {
                navigate("/folio/" + folio.id);
            }
        }
    };
    return (
        <>
            <label htmlFor="folio-name">Folio name</label>
            <input
                type="text"
                name="folio-name"
                id="folio-name"
                autoFocus
                onChange={handleChangeFolioName}
                value={folioName}
            />
            <div className="row end">
                <div className="button" onClick={handleCreateFolio}>
                    Create
                </div>
            </div>
            <Checkbox checked={isChecked} label="Go to folio after creation" onCheck={handleCheck} />
        </>
    );
};
