import { ChangeEvent, useContext, useEffect, useState } from "react";

import "../../css/filepicker.css";
import { AdvertiserPicker } from "./AdvertiserPicker";
import { CreativeSetPicker } from "./CreativeSetPicker";
import { DropZone } from "./DropZone";
import { Numbers } from "./Numbers";
import { Summary } from "./Summary";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { FolioPicker } from "./FolioPicker";
import { CroppedVideo } from "../videoEditor/CropWindow";
import { adnAdvertiser } from "../../functions/adnuntius/advertiser";
import { adnFolio } from "../../functions/adnuntius/folios";
import { adnCreativeSet } from "../../functions/adnuntius/creativeSet";
import { Tooltip } from "react-tooltip";
import { helpParser } from "../../functions/helpParser";
import { Overlay } from "../../App";

export const FilePicker = (props: {
    setToken: any;
    user: any;
    file: any;
    cropParams: any;
    setCropParams: any;
    cropArray: CroppedVideo[];
    setCropArray: any;
    overlay: Overlay | null;
    setOverlay: any;
}) => {
    const [hovering, setHovering] = useState<boolean>(false);
    const [file, setFile] = useState<any>(false);
    const [files, setFiles] = useState<any>([]);
    const [folio, setFolio] = useState<any>(false);
    const [urlBlob, setUrlBlob] = useState<any>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [advertiser, setAdvertiser] = useState<any>(false);
    const [creativeSet, setCreativeSet] = useState<any>(false);
    const [doneSteps, setDoneSteps] = useState<number>(1);
    const [name, setName] = useState<string>("");
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const navigate = useNavigate();
    const location = useLocation();

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setHovering(true);
    };
    const handleDragLeave = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setHovering(false);
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        props.setCropArray([]);
        props.setCropParams(undefined);
        console.log("did it!");
        // do smth with ev.dataTransfer.files
        if (e.dataTransfer.files.length > 0) {
            globalState.set.files(e.dataTransfer.files[0]);
            console.log("Multiple files!");
            const f = globalState.get.files();
            console.log(f);
            return false;
        }
        setFile(e.dataTransfer.files[0]);
        renderVideo(e.dataTransfer.files[0]);
        setName(e.dataTransfer.files[0].name.split(".")[0]);
    };

    const handleRemoveFile = (index: number) => {
        const f = [...files];
        f.splice(index, 1);
        console.log(f);
        setFiles(f);
        globalState.set.files(f);
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const renderVideo = async (file: any) => {
        const fileReader = new FileReader();
        await fileReader.readAsDataURL(file);
        setUrlBlob(URL.createObjectURL(file));
    };

    useEffect(() => {
        if (file) {
            console.log(file);
            if (file.type.includes("video")) {
                setExpanded(true);
            }
        }
    }, [file]);

    // Step counter
    useEffect(() => {
        if (files.length) {
            setExpanded(true);
        }
    }, [files]);

    useEffect(() => {
        let steps = 0;
        file && file.type.includes("video") && steps++;
        files.length > 0 && steps++;
        advertiser && steps++;
        creativeSet && steps++;
        folio && steps++;
        setDoneSteps(steps);
    }, [file, files, advertiser, creativeSet, folio]);

    useEffect(() => {
        if (name === "" && creativeSet.name) {
            setName(`Creative for ${creativeSet.name}`);
        }
    }, [creativeSet, name]);

    useEffect(() => {
        const multiFiles = globalState.get.files();
        if (props.file) {
            setFile(props.file);
        }
        if (props.file && file) {
            renderVideo(file);
        }
        if (multiFiles.length !== 0) {
            console.log("More files!");
            setFiles(multiFiles);
        } else if (!file && !props.file) {
            navigate("/");
        }
    }, [props.file, file, globalState.get, navigate]);

    useEffect(() => {
        const getAndSetAdvertiser = async (advertiserId: string) => {
            const adv = await adnAdvertiser(globalState.get.token(), advertiserId);
            setAdvertiser(adv);
        };
        const getAndSetFolio = async (folioId: string) => {
            const fol = await adnFolio(globalState.get.token(), folioId);
            setFolio(fol);
        };
        const getAndSetCreativeSet = async (creativeSetId: string) => {
            const crs = await adnCreativeSet(globalState.get.token(), creativeSetId);
            setCreativeSet(crs);
        };
        if (location.state) {
            const state = location.state as { advertiserId: string; folioId: string; creativeSetId: string };
            if (!advertiser && location.state.advertiserId) {
                getAndSetAdvertiser(state.advertiserId);
            }
            if (!folio && location.state.folioId) {
                getAndSetFolio(state.folioId);
            }
            if (!creativeSet && location.state.creativeSetId) {
                getAndSetCreativeSet(state.creativeSetId);
            }
        }
    }, [location]);

    return (
        <>
            {globalState.replaceCreative && (
                <div className="replaceBanner">Replacing creative "{globalState.replaceCreative.name}"</div>
            )}

            <div
                className="picker"
                style={{
                    padding: "2rem",
                    width: expanded ? "830px" : "270px",
                    marginLeft: expanded ? "-415px" : "-135px",
                    height: expanded ? "90%" : "500px",
                }}>
                <div className={`dialog`} style={{ width: expanded ? "830px" : "270px" }}>
                    <div
                        className="left"
                        style={
                            !expanded
                                ? {
                                      paddingRight: "1rem",
                                  }
                                : {}
                        }>
                        {!file || !file.type.includes("video") || (!files && <h2>Add your file</h2>)}
                        {file && file.type.includes("video") && <Numbers steps={5} done={doneSteps} />}
                        {files.length !== 0 && <Numbers steps={5} done={doneSteps} />}
                        <DropZone
                            handleDragEnter={handleDragEnter}
                            handleDragLeave={handleDragLeave}
                            handleDrop={handleDrop}
                            hovering={hovering}
                            urlBlob={urlBlob}
                            file={file}
                            files={files}
                            cropParams={props.cropParams}
                            handleRemoveFile={handleRemoveFile}
                            cropArray={props.cropArray}
                        />
                    </div>
                    <div
                        className={`advertisers`}
                        style={
                            expanded
                                ? {
                                      maxWidth: "550px",
                                      width: "550px",
                                      marginLeft: "1rem",
                                      paddingLeft: "0rem",
                                  }
                                : {}
                        }>
                        {!globalState.replaceCreative && (
                            <>
                                <AdvertiserPicker
                                    user={props.user}
                                    setAdvertiser={setAdvertiser}
                                    setCreativeSet={setCreativeSet}
                                    advertiser={advertiser}
                                    open={advertiser === false}
                                    setToken={props.setToken}
                                />
                                <FolioPicker
                                    user={props.user}
                                    folio={folio}
                                    setFolio={setFolio}
                                    setCreativeSet={setCreativeSet}
                                    advertiser={advertiser}
                                    open={advertiser && !folio}
                                />
                                <CreativeSetPicker
                                    setCreativeSet={setCreativeSet}
                                    folioId={folio.id ? folio.id : false}
                                    open={advertiser && folio && !creativeSet}
                                    order={creativeSet}
                                    advertiser={advertiser}
                                />
                            </>
                        )}

                        <Summary
                            advertiser={advertiser}
                            creativeSet={creativeSet}
                            name={name}
                            setName={setName}
                            setAdvertiser={() => {
                                setAdvertiser(false);
                                setCreativeSet(false);
                            }}
                            file={props.file}
                            files={files}
                            setCreativeSet={() => setCreativeSet(false)}
                            open={advertiser && creativeSet}
                            cropParams={props.cropParams}
                            setCropParams={props.setCropParams}
                            folio={folio}
                            cropArray={props.cropArray}
                            setCropArray={props.setCropArray}
                            overlay={props.overlay}
                            setOverlay={props.setOverlay}
                        />
                    </div>
                </div>
            </div>
            <Tooltip
                anchorSelect="#video-preview"
                render={() =>
                    helpParser(
                        "This is a preview of the uploaded video. It does not reflect the cropped proportions if crop is applied.\n\nClick the preview to go back and make changes."
                    )
                }
                style={{ width: 300 }}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".advertiser-picker.open .new-btn"
                render={() => helpParser("Create a new advertiser.")}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help && !advertiser}
            />
            <Tooltip
                anchorSelect=".folder-picker.open .new-btn"
                render={() => helpParser("Create a new folio.")}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help && !folio && advertiser}
            />
            <Tooltip
                anchorSelect=".order-picker.open .new-btn"
                render={() => helpParser("Create a new creative set.")}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help && folio && advertiser && !creativeSet}
            />
        </>
    );
};
