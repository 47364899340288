import { useContext, useEffect, useState } from "react";
import { GlobalStateManager, useGlobalState } from "../../contexts/useGlobalState";
import { adnCreativeSet, adnDeleteCreativeSet } from "../../functions/adnuntius/creativeSet";
import { BiArrowBack, BiCodeAlt, BiDotsVerticalRounded, BiImage, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { VideoStreamingOptions, adnCreatives, adnPostCreative } from "../../functions/adnuntius/creative";
import { Popup } from "../filePicker/Popup";
import { TagPicker } from "./TagPicker";
import { CreativeTable } from "./CreativeTable";
import { studioParser } from "../../functions/studioParser";
import { adnFolio } from "../../functions/adnuntius/folios";
import { BsBarChartLine, BsBriefcase } from "react-icons/bs";
import { Stats } from "../stats/Stats";
import { Tooltip } from "react-tooltip";
import { helpParser } from "../../functions/helpParser";
import { MdOutlineOpenInNew } from "react-icons/md";
import { ChangedCreative, Creative } from "./Creative";
import { AdnuntiusCreative } from "../types/AdnuntiusCreative";
import { by } from "../../functions/sort";
import CampaignManagerAuth from "../CampaignManagerAuth";
import { CampaignManager } from "./CampaignManager";
import { FaGoogle } from "react-icons/fa";
import { useUser } from "../../contexts/useUser";
import { hasGoogleAccess } from "../../functions/hasGoogleAccess";

export const CreativeSet = (props: { creativeSetId: string; setFile: any }) => {
    const globalState = useContext(useGlobalState) as GlobalStateManager;
    const [creativeSet, setCreativeSet] = useState<any>(false);
    const [folio, setFolio] = useState<any>(false);
    const [fetchingFolio, setFetchingFolio] = useState<boolean>(false);
    const [creatives, setCreatives] = useState<AdnuntiusCreative[] | undefined>(undefined);
    const [fetchingCreatives, setFetchingCreatives] = useState<boolean>(false);
    const [tagCollapsed, setTagCollapsed] = useState<boolean>(true);
    const [statsCollapsed, setStatsCollapsed] = useState<boolean>(false);
    const [creativesCollapsed, setCreativesCollapsed] = useState<boolean>(false);
    const [googleCollapsed, setGoogleCollapsed] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [waiting, setWaiting] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>("");
    const [studioObject, setStudioObject] = useState<{ [key: string]: string }>({});
    const [changedCreatives, setChangedCreatives] = useState<ChangedCreative[]>([]);

    const [openCreatives, setOpenCreatives] = useState<number[]>([]);

    const navigate = useNavigate();
    const user = useContext(useUser);

    const handleDelete = async () => {
        setLoadingText("Deleting creative set");
        setSuccess(false);
        setWaiting(true);
        setTimeout(async () => {
            const r = await adnDeleteCreativeSet(globalState.get.token(), creativeSet.id);
            if (r) {
                setSuccess(true);
                setTimeout(() => {
                    navigate("/advertiser/" + creativeSet.advertiser.id);
                }, 1000);
            }
        }, 1000);
    };

    const handleUpdateCreatives = () => {
        setCreatives(undefined);
    };

    useEffect(() => {
        const fetchCreativeSet = async () => {
            const r = await adnCreativeSet(globalState.get.token(), props.creativeSetId);
            if (r.type === "ErrorMessage") {
                globalState.set.token("");
            }
            setCreativeSet(r);
        };
        if (!creativeSet) {
            fetchCreativeSet();
        }
    }, [props.creativeSetId, creativeSet, globalState.set, globalState.get]);

    useEffect(() => {
        const fetchFolio = async () => {
            setFetchingFolio(true);
            const r = await adnFolio(globalState.get.token(), creativeSet.folio.id);
            if (r.type === "ErrorMessage") {
                globalState.set.token("");
            }
            setFolio(r);
            setFetchingFolio(false);
        };

        const fetchCreatives = async () => {
            setFetchingCreatives(true);
            const c = await adnCreatives(globalState.get.token(), props.creativeSetId);
            if (c.type === "ErrorMessage") {
                globalState.set.token("");
            }
            if (c?.results) {
                const parsedCreatives = c.results.map((creative: any) => {
                    let tempCreative = { ...creative };
                    if (creative?.description) {
                        tempCreative.description = JSON.parse(creative.description);
                    }
                    return tempCreative;
                });
                setCreatives(parsedCreatives);
            }
            setFetchingCreatives(false);
        };
        if (!creatives && !fetchingCreatives) {
            fetchCreatives();
        }
        if (creativeSet) {
            setStudioObject(studioParser(creativeSet.labels));
        }
        if (creativeSet && !folio && !fetchingFolio) {
            fetchFolio();
        }
    }, [
        creativeSet,
        creatives,
        fetchingCreatives,
        fetchingFolio,
        folio,
        globalState.get,
        globalState.set,
        props.creativeSetId,
    ]);

    const handleOpenAllCreatives = () => {
        const creativesArr: number[] = [];
        creatives?.forEach((c: AdnuntiusCreative, i: number) => creativesArr.push(i));
        setOpenCreatives(creativesArr);
    };
    const handleCloseAllCreatives = () => {
        setOpenCreatives([]);
    };

    const handleCreativeClick = (n: number) => {
        if (!openCreatives.includes(n)) {
            setOpenCreatives([...openCreatives, n]);
        } else {
            setOpenCreatives(openCreatives.filter((c) => c !== n));
        }
    };

    const onEndTranslate: { [key: string]: "loop" | "loop3x" | "stop" } = {
        "Loop forever": "loop",
        "Loop 3x": "loop3x",
        Stop: "stop",
    };

    const handleCreativeSave = async () => {
        if (!saving) {
            setSaving(true);
            const creativePromises: Promise<any>[] = [];
            changedCreatives.forEach((changedCreative) => {
                const ogCreative = creatives!.filter((c) => c.id === changedCreative.id)[0];
                const options: VideoStreamingOptions = {
                    layout: {
                        onEnd: onEndTranslate[changedCreative.onEnd],
                        url: changedCreative.clickUrl,
                    },
                    creativeId: changedCreative.id,
                    creativeName: changedCreative.name,
                    creativeSetId: creativeSet.id,
                    token: globalState.get.token(),
                };
                if (ogCreative.layoutParameters.video.id) {
                    options.layout["video"] = { id: ogCreative.layoutParameters.video.id };
                }
                if (changedCreative.impUrl && changedCreative.impUrl !== "") {
                    options.layout.impressionTrackingUrl = changedCreative.impUrl;
                }
                creativePromises.push(adnPostCreative.videoStreaming(options));
            });
            const creativesResponse = await Promise.all(creativePromises);
            console.log(creativesResponse);
            setSaving(false);
            setCreatives(undefined);
        }
    };

    const handleCreativeChanged = (creative: ChangedCreative) => {
        const isInArray = changedCreatives.filter((cre) => cre.id === creative.id)[0];
        if (!isInArray) {
            const newArr = [...changedCreatives];
            newArr.push(creative);
            setChangedCreatives(newArr);
        } else {
            const newArr = changedCreatives.filter((cre) => cre.id !== creative.id);
            newArr.push(creative);
            setChangedCreatives(newArr);
        }
        console.log(creative.name);
        // console.log(isInArray);
    };

    const handleReplaceCreative = (creative: any) => {
        globalState.set.replaceCreative(creative);
        props.setFile(false);
        navigate("/replace", {
            state: {
                advertiserId: folio.advertiser.id,
                folioId: folio.id,
                creativeSetId: creativeSet.id,
            },
        });
    };

    useEffect(() => {
        console.table(changedCreatives);
    }, [changedCreatives]);

    const sortedCreatives = creatives?.sort(by("name").direction("ascending"));

    return (
        <div className="creativeset">
            {waiting && <Popup success={success} loadingText={loadingText} successText={<p>Creative set deleted</p>} />}
            <div className="top">
                <span
                    className="back button"
                    onClick={() => {
                        navigate(`/folio/${creativeSet?.folio.id}`);
                    }}>
                    <BiArrowBack />
                </span>
                <div
                    className="add-creative button"
                    onClick={() => {
                        globalState.set.replaceCreative(false);
                        props.setFile(false);
                        navigate("/new", {
                            state: {
                                advertiserId: folio.advertiser.id,
                                folioId: folio.id,
                                creativeSetId: creativeSet.id,
                            },
                        });
                    }}>
                    <BiImage
                        style={{
                            float: "left",
                            marginRight: ".5rem",
                            position: "relative",
                            top: ".05rem",
                            fontSize: 16,
                        }}
                    />
                    <span>Add creative</span>
                </div>
                <span
                    className="menu"
                    onClick={() => {
                        console.log(document.getElementById("menu"));
                        document.getElementById("menu")?.focus();
                    }}>
                    <BiDotsVerticalRounded />
                </span>
                <div id="menu" className="menu-dialog" tabIndex={-1}>
                    <ul>
                        <li
                            onClick={() =>
                                window.open(
                                    "https://admin.adnuntius.com/creative-sets/creative-set/" +
                                        props.creativeSetId +
                                        "?accessToken=" +
                                        globalState.get.token()
                                )
                            }>
                            <MdOutlineOpenInNew /> Open in Adnuntius
                        </li>
                        <li onClick={handleDelete}>
                            <BiTrash style={{ color: "red" }} /> Delete
                        </li>
                    </ul>
                </div>
                <h2>
                    <div className="crumbs">
                        {folio && (
                            <>
                                <span
                                    onClick={() => {
                                        navigate("/advertiser/" + folio.advertiser.id);
                                    }}>
                                    {folio.advertiser.name}
                                </span>
                                <span className="spacer">&raquo;</span>
                                <span
                                    onClick={() => {
                                        navigate("/folio/" + folio.id);
                                    }}>
                                    {folio.name}
                                </span>
                            </>
                        )}
                    </div>
                    <BsBriefcase />
                    {creativeSet.name ? creativeSet.name : " "}
                </h2>
            </div>
            <div className="content">
                {creatives && (
                    <div className={tagCollapsed ? "container collapsed" : "container"}>
                        <div className={`tag ${creatives.length === 0 && "disabled"}`}>
                            <h3
                                onClick={() => {
                                    creatives.length !== 0 && setTagCollapsed(!tagCollapsed);
                                }}>
                                <BiCodeAlt
                                    size={20}
                                    style={{ float: "left", marginRight: "1rem", position: "relative", top: ".2rem" }}
                                />
                                <span>Campaign tag</span>
                            </h3>
                            {creativeSet && creatives && folio && (
                                <>
                                    <TagPicker
                                        tag={studioObject?.tag}
                                        creativeSet={creativeSet}
                                        creatives={creatives}
                                        advertiserName={folio.advertiser.name}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div className={statsCollapsed ? "container collapsed" : "container"}>
                    <div className="list-header" onClick={() => setStatsCollapsed(!statsCollapsed)}>
                        <h3>
                            <BsBarChartLine
                                style={{ float: "left", marginRight: "1rem", position: "relative", top: ".2rem" }}
                            />
                            <span className="stats-title">Stats</span>
                        </h3>
                    </div>
                    {creativeSet && <Stats campaign={creativeSet} />}
                </div>
                {creatives && (
                    <div className={creativesCollapsed ? "container collapsed" : "container"}>
                        <div className={`list-header ${creatives.length === 0 ? "disabled" : ""}`}>
                            <h3 onClick={() => creatives.length !== 0 && setCreativesCollapsed(!creativesCollapsed)}>
                                <BiImage
                                    style={{ float: "left", marginRight: "1rem", position: "relative", top: ".2rem" }}
                                />
                                <span className="creatives-title">Creatives</span>
                            </h3>
                        </div>
                        {creatives && creatives.length !== 0 && creativeSet && (
                            <span
                                className="expand-all"
                                onClick={() => {
                                    openCreatives.length !== creatives.length
                                        ? handleOpenAllCreatives()
                                        : handleCloseAllCreatives();
                                }}>
                                {openCreatives.length !== creatives.length ? "Expand all" : "Close all"}
                            </span>
                        )}
                        {creatives &&
                            creatives.length !== 0 &&
                            creativeSet &&
                            // <CreativeTable
                            //     creatives={creatives}
                            //     creativeSetId={creativeSet.id}
                            //     updateCreatives={handleUpdateCreatives}
                            //     folio={folio}
                            // />
                            sortedCreatives!.map((creative: AdnuntiusCreative, i: number) => (
                                <Creative
                                    creative={creative}
                                    small={!openCreatives.includes(i)}
                                    key={i}
                                    onClick={() => handleCreativeClick(i)}
                                    onChange={handleCreativeChanged}
                                    onSave={handleCreativeSave}
                                    onReplace={handleReplaceCreative}
                                />
                            ))}
                        {changedCreatives.length !== 0 && (
                            <div
                                className={`button save-creatives ${saving ? "disabled" : ""}`}
                                onClick={handleCreativeSave}>
                                {saving ? "Saving..." : "Save creatives"}
                            </div>
                        )}
                    </div>
                )}
                {hasGoogleAccess(user) && creatives && (
                    <div className={googleCollapsed ? "container collapsed" : "container"}>
                        <div className={`list-header`}>
                            <h3 onClick={() => setGoogleCollapsed(!googleCollapsed)}>
                                <FaGoogle
                                    style={{ float: "left", marginRight: "1rem", position: "relative", top: ".2rem" }}
                                />
                                <span className="google-title">Google Export</span>
                            </h3>
                        </div>
                        {!googleCollapsed && (
                            <CampaignManager creatives={creatives} creativeSetId={creativeSet.id}></CampaignManager>
                        )}
                    </div>
                )}
            </div>
            <Tooltip
                anchorSelect=".creatives-title"
                render={() => helpParser("These are the active creatives in this creative set.")}
                place="right"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".stats-title"
                render={() => helpParser("This shows live statistics for the creative set.")}
                place="right"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".menu"
                render={() => helpParser("This is an overflow menu with more actions for the creative set.")}
                style={{ width: 300 }}
                place="top-end"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".tag h3 span"
                render={() =>
                    helpParser(
                        "Allows you to generate a third party tag to deliver the contents of this creative set in any ad server."
                    )
                }
                place="right"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
            <Tooltip
                anchorSelect=".download-tag"
                render={() => helpParser("Downloads the selected tag to a file.")}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help && !tagCollapsed}
            />
            <Tooltip
                anchorSelect=".copy-tag"
                render={() => helpParser("Copies the selected tag to the clipboard.")}
                place="top-end"
                variant="info"
                opacity={1}
                isOpen={globalState.help && !tagCollapsed}
            />
            <Tooltip
                anchorSelect=".add-creative"
                render={() => helpParser("Adds a new creative to this creative set.")}
                place="left"
                variant="info"
                opacity={1}
                isOpen={globalState.help}
            />
        </div>
    );
};
